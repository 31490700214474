.delete-account-confirmation {
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    text-align: center;
  }

  h4 {
    margin: 4px 0;
    color: red;
  }

  &__buttons {
    margin-top: 24px;
  }
}
