@import '~@astrid/components/src/scss/utils';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  width: 100%;
  padding: 0 10px;

  &_small {
    margin: 12px 0;
  }
}

.line {
  height: 1px;
  flex: 1;
  background-color: $black-76;
}

.text {
  margin: 0 16px;
}
