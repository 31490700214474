@import '../../../scss/utils';

.word-race-exercise {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  flex: 1 0 auto;
  background-color: $very-light-pink;

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    min-height: 120px;
    flex-wrap: wrap;
    transition: 500ms height;

    & > p {
      margin: 0;
    }
  }

  &__character {
    width: 350px;
    height: 350px;
    @include breakpoint(xs) {
      width: 270px;
      height: 270px;
    }
  }

  &__word {
    background-color: transparent;
    margin: 10px;
    padding: 10px 26px;
    border-radius: 12px;
    position: relative;

    svg {
      position: absolute;
      width: 30px;
      height: 30px;
      top: -8px;
      right: -8px;
      border-radius: 100px;
      padding: 4px;
      background-color: $success-green;
      border: 3px solid $very-light-pink;
      fill: white;
    }

    p {
      margin: 0;
    }

    &--success {
      @extend .word-race-exercise__word;
      background-color: $success-green;
      animation: grow 500ms 1;
      p {
        color: white !important;
      }
    }

    &--wrong {
      @extend .word-race-exercise__word;
      animation: grow 500ms 1, shake-horizontal 330ms 500ms 1 normal;
      p {
        color: $cerise !important;
      }
    }
  }
}

@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
