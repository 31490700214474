.astrid-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000;

  & > button {
    margin-top: 24px;
  }

  &__content {
    position: relative;
    padding: 32px 40px;
    background-color: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    max-height: 80vh;
  }
  &__close-icon {
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;
    border-radius: 100%;
    transition: 230ms all;
    padding: 2px;
    width: 32px;
    height: 32px;
    &:hover {
      background-color: rgba(black, 0.2);
    }

    &:active {
      background-color: rgba(black, 0.3);
    }
  }
}
