@import '../../scss/utils';

.text-input {
  position: relative;
  width: 100%;
  margin: 8px 4px;

  label {
    position: absolute;
    top: 22px;
    left: 16px;
    color: $black-30;
    font-size: 22px;
    z-index: 1;
    transition: all 150ms;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 32px);
  }

  input {
    outline: none;
    font-size: 22px;
    z-index: 2;
    width: 100%;
    color: $black-76;
    padding: 22px 16px 18px;
    background-color: white;
    border-radius: 20px;
    border: 2px solid $light-grey;

    &:disabled {
      color: $black-30 !important;
      border-color: lighten($light-grey, 3);
    }

    &:focus ~ label,
    &:not([value='']) ~ label,
    &:-webkit-autofill ~ label {
      top: 8px;
      font-size: 12px;
    }
  }

  &--error {
    input {
      border-color: $error;
    }
  }

  &__helper-text {
    margin: 8px 0 0;
    padding: 0 16px;
    font-size: 14px;
    color: $text-secondary;

    .text-input--error & {
      color: $error;
    }
  }
}
