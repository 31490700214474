@import '../../scss/utils';

.astrid-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: inset 0 -4px 0 0 $light-grey;
  border: solid 2px $light-grey;

  &__header {
    padding: 32px;
    border-bottom: solid 1px $light-grey;

    h4,
    p {
      margin: 0;
    }
  }

  &__note {
    color: rgba(10, 10, 10, 0.5);
  }

  &__content {
    padding: 32px;
  }
}
