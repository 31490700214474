@import '../../../scss/utils';

.building-exercise {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1 0 auto;
  min-height: 100%;
  padding: 0px 20px 0;

  @include breakpoint(xs) {
    padding: 24px 8px 16px;
  }

  &--word {
    @extend .building-exercise;
    background-color: $very-light-blue;
  }
  &--sentence {
    @extend .building-exercise;
    background-color: $egg-shell;
  }

  &__construction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__word-spacing {
    margin: 0 5px;
  }

  &__character {
    width: 350px;
    height: 350px;
    margin-top: 40px;
    z-index: 1;
    @include breakpoint(xs) {
      width: 300px;
      height: 300px;
      margin-top: 16px;
    }
  }

  &__correctly-placed {
    border-radius: 20px;
    background-color: white;
    margin: 2px 5px;
    padding: 18px 22px;
    animation: correct-placement 600ms 1 ease;
    @include breakpoint(xs) {
      padding: 10px 14px;
      margin: 2px 3px;
    }
  }

  &__candidate-pool {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -125px;
    min-height: 68px;
    z-index: 2;
  }

  &__candidate {
    background-color: white;
    padding: 16px 24px;
    border-radius: 24px;
    margin: 4px 8px 6px;
    border: 2px solid $light-grey;
    box-shadow: 0 4px $light-grey;
    outline: none;
    cursor: grab;
    z-index: 3;
    touch-action: none;
    -webkit-user-select: none;

    p {
      margin: 0;
    }

    &--dragging {
    }

    &--incorrect {
      background-color: $rosy-pink;
      box-shadow: none;
      border: 2px solid mix($rosy-pink, black, 75%);
      box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.25);
      animation: $shake-animation;
    }

    @include breakpoint(xs) {
      padding: 8px 16px;
      margin: 2px 6px 4px;
      border-radius: 20px;
    }
  }

  &__dropzone {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 24px;
    border: 2px dashed $black-76;
    height: 56px;
    width: 80px;
    margin: 2px 5px;
    &--word {
      @extend .building-exercise__dropzone;
      height: 68px;
      width: 118px;
      @include breakpoint(xs) {
        height: 52px;
        width: 102px;
        border-radius: 24px;
      }
    }
    &--char {
      @extend .building-exercise__dropzone;
      height: 68px;
      width: 78px;
      @include breakpoint(xs) {
        height: 52px;
        width: 62px;
        border-radius: 24px;
      }
    }
  }
}

@keyframes correct-placement {
  0% {
    box-shadow: 0 0 0 0 rgba($seafoam-blue, 0.7);
    z-index: 5;
  }

  99% {
    box-shadow: 0 0 3px 40px rgba($seafoam-blue, 0.05);
    z-index: 5;
  }

  100% {
    box-shadow: none;
    z-index: unset;
  }
}
