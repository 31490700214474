@import '../../scss/utils';

.welcome-animation {
  width: 100%;
  height: 100%;
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: $pale-salmon;

  &__background {
    animation-name: welcome-anim-bg;
    animation-duration: 6s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: step-start;
    animation-fill-mode: forwards;
  }

  &__fade {
    animation-name: welcome-anim-fade;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }
}

@keyframes welcome-anim-fade {
  from {
    background-color: $pale-salmon;
  }

  to {
    background-color: white;
  }
}

@keyframes welcome-anim-bg {
  2% {
    background-color: white;
  }

  9% {
    background-color: $robin-s-egg;
  }

  15% {
    background-color: $cerise;
  }

  21% {
    background-color: $piss-yellow;
  }

  100% {
    background-color: $pale-salmon;
  }
}
