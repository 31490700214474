@import '../../../scss/utils';

.book-carousel-item {
  height: 250px;
  margin: 0 0.5rem;
  background: #eee no-repeat;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 230ms all;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25), 0 7px 7px rgba(0, 0, 0, 0.22);
  }

  &__cover {
    width: 100%;
    flex: 1 0 auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__status {
    width: 100%;
    flex: 1 0 auto;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &--left {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 1 0 auto;
    }

    &--right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex: 1 0 auto;
      padding: 4px 0 0 0;
    }
  }

  &__badge {
    font-size: 15px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 10px;
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &--jumpBack {
      @extend .book-carousel-item__badge;
      background-color: $maize;
      color: black;
      margin: 4px 4px 0 0;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 10px solid black;
        margin-right: 4px;
      }
    }

    &--read {
      @extend .book-carousel-item__badge;
      background-color: $aqua-blue;
      color: white;
      margin: 4px 4px 0 0;
    }

    &--recommended {
      @extend .book-carousel-item__badge;
      background-color: $robin-s-egg;
      color: white;
      margin: 4px 4px 0 0;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 10px solid white;
        margin-right: 4px;
      }
    }

    &--stars {
      @extend .book-carousel-item__badge;
      background-color: white;
      color: black;
      font-weight: bold;
      margin-bottom: 4px;
      svg {
        width: 15px;
        height: 15px;
        margin-right: 2px;
        fill: $maize;
      }
    }

    &--cefr {
      @extend .book-carousel-item__badge;
      background-color: white;
      margin-bottom: 4px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 12px 14px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
    justify-content: flex-start;
    background-color: white;

    &-text {
      font-size: 1.2rem;
      flex: 1 0 0;
      text-align: center;
      color: black;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 1.4rem;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 8px;

        &[data-role='type'] {
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
          padding: 1px;
          border-radius: 100%;
        }
      }
    }
  }
}
