.avatar-upload {
  display: flex;
  position: relative;
  height: fit-content;
  width: fit-content;
  input {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    font-size: 12px;
    border: 1px solid lightgrey;
    box-shadow: 0 3px lightgray;
    transition: 230ms all;
    margin: 0;
    background-color: white;

    &:active {
      transform: translateY(2px);
      box-shadow: 0 1px lightgray;
    }
  }

  span {
    position: absolute;
    text-align: center;
    color: red;
    font-size: 11px;
    bottom: 0;
    transform: translate(-50%, 105%);
    left: 50%;
    width: 100%;
  }
}

.avatar-display {
  & > div {
    position: relative;
    display: flex;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin: 0;
    border-radius: 100px;
    box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.25);
  }

  &__overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    transition: 150ms all;

    svg {
      fill: white;
      width: 40px;
      height: 40px;
    }

    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
