@import '../../scss/utils';

.toggle-switch {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: $very-light-grey;
  border-radius: 16px;
  height: fit-content;
  width: fit-content;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
  }

  label {
    cursor: pointer;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    border: solid 2px $very-light-grey;
    transition: 150ms all;
  }

  input:checked + label {
    background-color: white;
    border: solid 2px $light-grey;
    box-shadow: inset 0 -4px 0 0 $light-grey;
  }
}
