@import '~@astrid/components/src/scss/utils';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  text-align: center;
  max-width: 450px;
}

.steps_container {
  display: flex;
  flex-direction: column;
}

.step {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  @include breakpoint(xs) {
    flex-direction: column;
    align-items: center;
  }
}

.step_text_container {
  padding: 1rem 1.5rem 0 0;
  flex: 1;
  width: 300px;
  @include breakpoint(xs) {
    padding: 0;
    margin-bottom: 1rem;
  }
}

.step_img {
  flex: 1;
  width: 150px;
  height: 400px;
  object-fit: contain;
  @include breakpoint(xs) {
    width: 200px;
  }
}
