@import '../../scss/utils';

.audio-recorder {
  border-radius: 100%;
  animation: pulse-maize 2s infinite;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $maize;
    border-radius: 100%;
    padding: 30px;
    transition: 230ms all;
    -ms-touch-action: none;
    touch-action: none;
    user-select: none;
    box-shadow: 0 4px darken($maize, 30%);

    svg {
      width: 32px;
      height: 32px;
    }

    &:active {
      box-shadow: 0 1px darken($pale-salmon, 30%);
      transform: translateY(3px);
    }
  }

  &--recording {
    @extend .audio-recorder;
    animation: pulse-salmon 1.2s infinite;
    button {
      animation: none;
      background-color: $pale-salmon;
    }
  }
}

@keyframes pulse-maize {
  0% {
    box-shadow: 0 5px 0 0 rgba($maize, 0.7);
  }

  90% {
    box-shadow: 0 5px 0 20px rgba($maize, 0);
  }

  100% {
    box-shadow: 0 5px 0 0 rgba($maize, 0);
  }
}

@keyframes pulse-salmon {
  0% {
    transform: scale(0.95);
    box-shadow: 0 5px 0 0 rgba(255, 190, 168, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 5px 0 40px rgba(255, 190, 168, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 5px 0 0 rgba(255, 190, 168, 0);
  }
}
