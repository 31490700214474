@import '../../scss/utils';

.chip {
  padding: 0 16px;
  line-height: 56px;
  border-radius: 20px;
  background-color: $very-light-grey;
  display: flex;
  align-items: center;

  &__avatar {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 8px;
    font-family: $lexendFont;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: white;
    margin-right: 8px;
  }
}

.chips-factory {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > span {
    margin: 4px 8px;
  }
}
