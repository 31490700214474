@import '~@astrid/components/src/scss/utils';

.container {
  width: 100%;
  padding-top: 4.25rem;
  margin: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
}
