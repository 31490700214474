@import '../../../scss/utils';

.listening-exercise {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100%;
  flex: 1 0 auto;
  flex-direction: column;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
  background-color: $blue-grey;
  @include breakpoint(xs) {
    padding: 4px 4px 16px;
  }

  &__content {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;

    img {
      max-height: 450px;
      border-radius: 10px;
      margin-bottom: 32px;
      box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.25);

      @include breakpoint(xs) {
        width: calc(100% - 1rem);
        max-width: 330px;
      }
    }
  }
}
