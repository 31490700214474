@import '../../scss/utils';

$hiddenPosition: -70px;
$visiblePosition: 20px;

.alert-wrapper {
  position: fixed;
  bottom: $hiddenPosition;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s;
  border: 2px solid $error;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  background-color: white;
  max-width: 400px;
  max-height: 64px;
  overflow: hidden;

  svg {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
  }

  p {
    width: calc(100% - 18px);
    overflow: hidden;
    max-height: 40px;
    line-height: 20px;
    font-size: 18px;
    margin: 0;
  }

  &--open {
    @extend .alert-wrapper;
    bottom: $visiblePosition;
    z-index: 10000;
  }

  &--closed {
    @extend .alert-wrapper;
    bottom: $hiddenPosition;
  }
}
