@import '../../scss/utils';

.GoogleButton {
  padding: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: medium;
  svg {
    width: 24px;
    height: 24px;
    fill: white;
    margin-right: 10px;
  }

  &--primary {
    background-color: $cobalt-blue;
    color: white;
  }

  &--secondary {
    background-color: white;
    border: 2px solid $light-grey;
  }
}
