@import '../../scss/utils';

@mixin buttonPressEffect($buttonColor, $shadowColor) {
  box-shadow: 0 4px $shadowColor;

  &:active {
    box-shadow: 0 2px $shadowColor;
    transform: translateY(2px);
  }
}

@mixin outlinedButtonPressEffect($borderColor) {
  box-shadow: 0 4px $borderColor;
  &:active {
    box-shadow: 0 2px $borderColor;
    transform: translateY(2px);
  }
}

@mixin smallPaddingAndRadius {
  padding: 9px 12px 11px 12px;
  border-radius: 14px;
  svg {
    width: 18px;
    height: 18px;
  }
}

@mixin defaultPaddingAndRadius {
  padding: 18px 20px;
  border-radius: 20px;
  svg {
    width: 25px;
    height: 25px;
  }
}

@mixin defaultButtonStyles {
  font: 1rem/1.25rem $lexendFont;
}

@mixin outlinedButton($borderColor, $textColor) {
  color: $textColor;
  background-color: white;
  border: solid 2px $light-grey;
  transition: 150ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  @include outlinedButtonPressEffect($borderColor);

  &:disabled {
    box-shadow: 0 4px $light-grey;
    border: solid 2px $light-grey;
    color: $light-grey !important;
    svg {
      fill: $light-grey !important;
    }

    span {
      color: $light-grey !important;
    }
  }

  & > svg {
    fill: black !important;
  }
}

@mixin filledButton($fillColor, $textColor, $shadowColor: darken($fillColor, 25%)) {
  & > span {
    color: $textColor !important;
  }
  color: $textColor !important;
  background-color: $fillColor;
  transition: 150ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  @include buttonPressEffect($fillColor, $shadowColor);
  &:disabled {
    box-shadow: 0 4px darken($light-grey, 25%);
    background-color: $light-grey;
    color: grey !important;

    span {
      color: grey !important;
    }

    svg {
      fill: grey !important;
    }
  }

  & > svg {
    fill: $textColor !important;
  }
}

@mixin flatButton($fillColor, $textColor) {
  color: $textColor !important;
  background-color: $fillColor;
  transition: 150ms all;
  position: relative;
  overflow: hidden;
  &:hover {
    background-color: darken($fillColor, 5%);
  }

  &:disabled {
    background-color: $light-grey;
    color: grey !important;
  }

  & > svg {
    fill: $textColor !important;
  }
}

.button {
  &--small {
    @include defaultButtonStyles();
    @include smallPaddingAndRadius();
  }

  &--default {
    @include defaultButtonStyles();
    @include defaultPaddingAndRadius();
  }

  &--color {
    &-default {
      &.button--filled {
        @include filledButton($maize, black);
      }

      &.button--outlined {
        @include outlinedButton($light-grey, black);
      }

      &.button--flat {
        @include flatButton($blue-grey, black);
      }
    }

    &-light-blue {
      &.button--filled {
        @include filledButton($robin-s-egg, white);
      }
      &.button--flat {
        @include flatButton($robin-s-egg, white);
      }
    }

    &-green {
      &.button--filled {
        @include filledButton($green, white);
      }
      &.button--flat {
        @include flatButton($green, white);
      }
    }

    &-blue-grey {
      &.button--filled {
        @include filledButton($blue-grey, black, $grey);
      }
      &.button--flat {
        @include flatButton($blue-grey, black);
      }
    }

    &-primary {
      &.button--filled {
        @include filledButton($maize, black);
      }
      &.button--flat {
        @include flatButton($maize, black);
      }
    }

    &-secondary {
      &.button--filled {
        @include filledButton($rosy-pink, white);
      }
      &.button--flat {
        @include flatButton($rosy-pink, white);
      }
    }

    &-action {
      &.button--filled {
        @include filledButton($cobalt-blue, white, darken($cobalt-blue, 10%));
      }
      &.button--flat {
        @include flatButton($cobalt-blue, white);
      }
    }

    &-confirm {
      &.button--filled {
        @include filledButton($aqua-blue, white);
      }
      &.button--flat {
        @include flatButton($aqua-blue, white);
      }
    }
  }

  &--spacing {
    &-small {
      & > svg {
        margin-right: 4px;
      }
    }
    &-default {
      & > svg {
        margin-right: 6px;
      }
    }
  }
}
