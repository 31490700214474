@import '~@astrid/components/src/scss/utils';

.container {
  width: 100%;
  background-color: white;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
}

.inner_container {
  max-width: 1440px;
  width: 100%;
  height: 4.25rem;
  padding: 0 1.25rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.buttons_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include breakpoint(xs) {
    display: none;
  }
}

.store_btn {
  margin-left: 10px;
  height: 3rem;
}

.store_img {
  height: 3rem;
}

.logo {
  height: 2.5rem;
  width: 10rem;
}

.download {
  display: none;

  @include breakpoint(xs) {
    display: block;
  }
}

.btn {
  font-size: 1rem;
  padding: 0.75rem 1rem;
  margin-left: 5px;
}

.right_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
