.headline {
  display: flex;
  flex-direction: row;

  img {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border-radius: 100%;
    object-fit: cover;
  }

  h2,
  p {
    margin: 0;
  }
}
