@import '../../scss/utils';

.book-cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  width: 100%;
  background-color: $blue-grey;

  &__title {
    text-align: center;
  }

  @include breakpoint(sm) {
    justify-content: flex-start;
    padding-bottom: 16px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 40px 0 130px;

    @include breakpoint(sm) {
      flex-direction: column;
      margin: 0 0 1rem;
    }

    @include breakpoint(xs) {
      width: 100%;
    }
  }

  &__img-loader {
    height: 240px !important;
    width: 400px !important;
    margin-right: 32px;
    border-radius: 10px;
    flex: 1 0 auto;
    @include breakpoint(sm) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    @include breakpoint(xs) {
      width: calc(100% - 1rem);
      height: 200px;
      max-width: 400px;
    }
  }

  img {
    object-fit: cover;
    height: 240px;
    width: 400px;
    margin-right: 32px;
    border-radius: 10px;
    box-shadow: 0px 3px 4px lightgrey;
    @include breakpoint(sm) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    @include breakpoint(xs) {
      width: calc(100% - 1rem);
      height: 200px;
      max-width: 400px;
    }
  }

  &__info {
    list-style: none;
    flex: 1 0 auto;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    background-color: white;
    border: 2px solid lightgrey;
    height: 240px;
    width: 400px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 4px lightgrey;

    @include breakpoint(xs) {
      width: calc(100% - 1rem);
      height: 200px;
      max-width: 400px;
    }

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      padding: 0 32px;
      border-bottom: 1px solid lightgrey;
      font-size: 18px;

      &:last-child {
        border: none;
      }

      span {
        color: lightgrey;
        font-size: 14px;
        margin-bottom: 3px;
      }
    }
  }
}
