@import '../../../scss/utils';
.comprehension-exercise {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  flex: 1 0 auto;
  background-color: $blue-grey;
  padding: 56px 24px 0px;
  text-align: center;
  @include breakpoint(xs) {
    padding: 0 8px 16px;
  }

  &__answers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__answer {
    background-color: white;
    border-radius: 24px;
    box-shadow: inset 0 -4px 0 0 $light-grey;
    border: solid 2px $light-grey;
    padding: 12px 22px;
    margin: 2px 8px;
    transition: background-color 230ms cubic-bezier(0, 0.68, 1, 0.79);
    cursor: pointer;
    p {
      margin: 0;
    }

    &--wrong {
      @extend .comprehension-exercise__answer;
      box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.25);
      background-color: $rosy-pink;
      animation: wrong-pulse 1s normal;
    }

    &--correct {
      @extend .comprehension-exercise__answer;
      box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.25);
      background-color: $seafoam-blue;
      animation: correct-pulse 1s normal;
    }

    @include breakpoint(xs) {
      padding: 8px 16px 12px;
    }
  }

  &__character {
    width: 350px;
    height: 350px;
    margin-top: 32px;
    @include breakpoint(xs) {
      width: 250px;
      height: 250px;
    }
  }
}

@keyframes correct-pulse {
  0% {
    border-color: transparent;
    box-shadow: none;
  }
  50% {
    transform: scale(1.05);
    border-color: transparent;
    box-shadow: 0 0 0 20px rgba(111, 226, 220, 0.5);
  }
  100% {
    border-color: transparent;
    box-shadow: none;
  }
}

@keyframes wrong-pulse {
  0% {
    border-color: transparent;
    box-shadow: none;
  }
  50% {
    border-color: transparent;
    transform: rotate(2deg);
    box-shadow: 0 0 0 20px rgba(247, 105, 136, 0.5);
  }
  100% {
    border-color: transparent;
    box-shadow: none;
  }
}
