@import '../../scss/utils';

$side-padding: 16px;

.custom-select {
  position: relative;
  width: 100%;
  color: $black-76;
  margin: 8px 4px;
  outline: none;

  &__display {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px $side-padding 16px;
    width: 100%;
    border: 2px solid $light-grey;
    background-color: $very-light-grey;
    border-radius: 20px;
    position: relative;
    text-align: left;
    font-size: 22px;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px lightskyblue;
    }

    & > div {
      width: 100%;
      height: 28px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &--error {
      @extend .custom-select__display;
      border-color: $cerise;
    }

    &--disabled {
      @extend .custom-select__display;
      cursor: auto;
      border-color: lighten($light-grey, 3);
      color: $black-30;

      svg {
        fill: $black-30;
      }
    }

    &-label {
      position: absolute;
      top: 20px;
      left: $side-padding;
      font-size: 22px;
      color: $black-30;
      transition: 100ms all;

      &--up {
        @extend .custom-select__display-label;
        top: 8px;
        font-size: 12px;
      }
    }
  }

  &__options {
    visibility: hidden;
    list-style: none;
    padding: 0;
    position: absolute;
    z-index: 2;
    border: 2px solid $light-grey;
    background-color: $very-light-grey;
    margin: 8px 0 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 150px;
    li {
      display: flex;
      flex-direction: row;

      input {
        content: '';
        display: none;
      }

      label {
        width: 100%;
        padding: 8px $side-padding;
        font-size: 20px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        &[data-preselected='true'] {
          background-color: rgba(black, 0.2);
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    &--open {
      visibility: visible;
    }

    &--position-top {
      bottom: 100%;
      margin: 0 0 8px;
    }
  }

  &__helper-text {
    color: $error;
    margin: 8px 0 0;
    padding: 0 16px;
    font-size: 14px;
  }
}
