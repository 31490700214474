@import '../../../../scss/utils';

.word-score {
  position: relative;

  &__word {
    padding: 0px 4px;
    margin: 0;
    cursor: pointer;
    transition: 200ms all;

    &--wrong {
      @extend .word-score__word;
      color: $cerise !important;
      animation: $shake-animation;
    }

    &--ok {
      @extend .word-score__word;
      color: $burnt-yellow;
    }

    &--great {
      @extend .word-score__word;
      color: $success-green !important;
    }
  }

  &__sounded-like {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translate(-50%);
  }

  &__popup {
    position: absolute;
    display: none;
    background-color: white;
    z-index: 10;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: left;
    padding: 8px;
    white-space: nowrap;
    border-radius: 10px;
    border-spacing: 0px;
    border-collapse: collapse;

    td,
    th {
      border: 1px solid lightgray;
      padding: 4px 8px;
    }

    th {
      background-color: $cobalt-blue;
      border-color: $cobalt-blue;
      color: white !important;
    }

    &--open {
      @extend .word-score__popup;
      display: block;
      animation: custom-grow 200ms 1 linear;
    }
  }

  &__phoneme {
    color: $black-76;
    &--wrong {
      @extend .word-score__phoneme;
      color: $cerise !important;
    }

    &--ok {
      @extend .word-score__phoneme;
      color: $burnt-yellow;
    }

    &--great {
      @extend .word-score__phoneme;
      color: $success-green !important;
    }
  }
}

@keyframes custom-grow {
  0% {
    transform: scale(0) translateX(-50%);
  }
  80% {
    transform: scale(1.05) translateX(-50%);
  }
  100% {
    transform: scale(1) translateX(-50%);
  }
}
