.circular-progress {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__children {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
