.replay-sentence {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 20px;
  cursor: pointer;
  background: transparent;
  transition: 230ms all;

  svg {
    width: 26px;
    height: 26px;
    margin-right: 4px;
  }

  &:hover {
    opacity: 0.7;
  }

  &--playing {
    @extend .replay-sentence;
    svg {
      animation: spin 1s infinite linear;
    }
    &:hover {
      opacity: 1;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
