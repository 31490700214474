.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.level_icon {
  margin-right: 1rem;
}

.button {
  width: 100%;
  font-size: 1.25rem;
  margin-top: 2rem;
}

.text {
  align-self: flex-start;
  margin-bottom: 1rem;
}

.error {
  color: red;
}
