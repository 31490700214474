@import '../../scss/utils';

.difficult-words {
  display: flex;
  position: relative;
  width: 100%;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $blue-grey;
  @include breakpoint(xs) {
    padding: 4px 4px 16px;
  }

  &__content {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  &__words {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 80px;

    p {
      margin: 4px 0;
    }
  }

  &__score-color {
    &--wrong {
      color: $cerise !important;
      animation: $shake-animation;
    }

    &--ok {
      color: $burnt-yellow !important;
    }

    &--great {
      color: $success-green !important;
    }
  }

  &__listen-button {
    position: absolute;
    top: 28px;
    right: 80px;
    @include breakpoint(xs) {
      top: 16px;
      right: 10px;
    }
  }

  &__finish {
    position: absolute;
    bottom: 28px;
    right: 80px;
    svg {
      width: 20px;
      height: 20px;
      margin-left: 4px;
    }
    @include breakpoint(xs) {
      bottom: 16px;
      right: 10px;
    }
  }
}
