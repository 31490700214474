@import '../../scss/colors';

.permissions-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000;

  & > button {
    margin-top: 24px;
  }

  &__content {
    padding: 32px 36px;
    background-color: $maize;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;

    & > p {
      margin: 0;
      text-align: center;
    }

    & > h4 > span {
      color: #8ab4f8;
      font-family: sans-serif;
      font-weight: bold;
    }

    & > svg {
      width: 30px;
      height: 30px;
      float: right;
      margin-left: auto;
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;

    svg {
      &:first-of-type {
        margin-right: 10px;
        position: absolute;
        transform: translate(-105%, -25%);
      }
      &:last-of-type {
        padding: 22px;
        width: 76px;
        height: 76px;
        border-radius: 100%;
        border: 1px solid black;
      }
    }
  }
}
