.onboarding-book {
  display: flex;
  flex: 1 0 auto;
  position: relative;
  width: 100%;
  min-height: 100%;

  img {
    box-shadow: none;
  }

  &__navigation {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    pointer-events: none;

    svg {
      width: 80px;
      height: 100%;
      padding: 0 15px;
      cursor: pointer;
      transition: 300ms all;
      pointer-events: all;

      &:hover {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
      }

      &:first-of-type {
        transform: rotate(180deg);
      }
    }
  }

  &__tip {
    position: absolute;
    top: 16px;
    left: 50%;
    background-color: rgba(black, 0.4);
    padding: 10px 20px;
    border-radius: 10px;
    transform: translate(-50%);
    p {
      margin: 0;
      color: white;
    }
  }
}
