$workSansFont: 'Work Sans', sans-serif;
$lexendFont: 'Lexend', sans-serif;

$shake-animation: shake-horizontal 330ms normal;
$spinner-perspective: 400px;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 600px) {
      @content;
    }
  } @else if $class == sm {
    @media (max-width: 960px) {
      @content;
    }
  } @else if $class == md {
    @media (max-width: 1280px) {
      @content;
    }
  } @else if $class == lg {
    @media (max-width: 1450px) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  from {
    -webkit-transform: perspective($spinner-perspective) rotateY(0deg);
  }
  to {
    -webkit-transform: perspective($spinner-perspective) rotateY(-360deg);
  }
}

/* all other browsers */
@keyframes spinner {
  from {
    -moz-transform: perspective($spinner-perspective) rotateY(0deg);
    -ms-transform: perspective($spinner-perspective) rotateY(0deg);
    transform: perspective($spinner-perspective) rotateY(0deg);
  }
  to {
    -moz-transform: perspective($spinner-perspective) rotateY(-360deg);
    -ms-transform: perspective($spinner-perspective) rotateY(-360deg);
    transform: perspective($spinner-perspective) rotateY(-360deg);
  }
}

@keyframes shake-horizontal {
  2% {
    transform: translate(-6px, 0) rotate(0);
  }
  4% {
    transform: translate(9px, 0) rotate(0);
  }
  6% {
    transform: translate(-6px, 0) rotate(0);
  }
  8% {
    transform: translate(-2px, 0) rotate(0);
  }
  10% {
    transform: translate(8px, 0) rotate(0);
  }
  12% {
    transform: translate(-6px, 0) rotate(0);
  }
  14% {
    transform: translate(0px, 0) rotate(0);
  }
  16% {
    transform: translate(-1px, 0) rotate(0);
  }
  18% {
    transform: translate(9px, 0) rotate(0);
  }
  20% {
    transform: translate(-4px, 0) rotate(0);
  }
  22% {
    transform: translate(-6px, 0) rotate(0);
  }
  24% {
    transform: translate(-5px, 0) rotate(0);
  }
  26% {
    transform: translate(-9px, 0) rotate(0);
  }
  28% {
    transform: translate(-4px, 0) rotate(0);
  }
  30% {
    transform: translate(2px, 0) rotate(0);
  }
  32% {
    transform: translate(0px, 0) rotate(0);
  }
  34% {
    transform: translate(0px, 0) rotate(0);
  }
  36% {
    transform: translate(1px, 0) rotate(0);
  }
  38% {
    transform: translate(-1px, 0) rotate(0);
  }
  40% {
    transform: translate(0px, 0) rotate(0);
  }
  42% {
    transform: translate(1px, 0) rotate(0);
  }
  44% {
    transform: translate(-8px, 0) rotate(0);
  }
  46% {
    transform: translate(-8px, 0) rotate(0);
  }
  48% {
    transform: translate(8px, 0) rotate(0);
  }
  50% {
    transform: translate(3px, 0) rotate(0);
  }
  52% {
    transform: translate(-4px, 0) rotate(0);
  }
  54% {
    transform: translate(3px, 0) rotate(0);
  }
  56% {
    transform: translate(-8px, 0) rotate(0);
  }
  58% {
    transform: translate(0px, 0) rotate(0);
  }
  60% {
    transform: translate(-9px, 0) rotate(0);
  }
  62% {
    transform: translate(-4px, 0) rotate(0);
  }
  64% {
    transform: translate(2px, 0) rotate(0);
  }
  66% {
    transform: translate(1px, 0) rotate(0);
  }
  68% {
    transform: translate(-7px, 0) rotate(0);
  }
  70% {
    transform: translate(-8px, 0) rotate(0);
  }
  72% {
    transform: translate(5px, 0) rotate(0);
  }
  74% {
    transform: translate(-7px, 0) rotate(0);
  }
  76% {
    transform: translate(6px, 0) rotate(0);
  }
  78% {
    transform: translate(-6px, 0) rotate(0);
  }
  80% {
    transform: translate(4px, 0) rotate(0);
  }
  82% {
    transform: translate(-8px, 0) rotate(0);
  }
  84% {
    transform: translate(-3px, 0) rotate(0);
  }
  86% {
    transform: translate(2px, 0) rotate(0);
  }
  88% {
    transform: translate(-1px, 0) rotate(0);
  }
  90% {
    transform: translate(1px, 0) rotate(0);
  }
  92% {
    transform: translate(-5px, 0) rotate(0);
  }
  94% {
    transform: translate(-8px, 0) rotate(0);
  }
  96% {
    transform: translate(0px, 0) rotate(0);
  }
  98% {
    transform: translate(-8px, 0) rotate(0);
  }
  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }
}

@keyframes grow {
  0% {
    transform: scale(0.01);
  }
  80% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@mixin chip-styling($smallSize: false) {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: $blue-grey;
  position: relative;
  color: black;
  cursor: pointer;
  width: fit-content;
  transition: 230ms all;

  &:hover {
    background-color: darken($blue-grey, 2%);
  }

  @if ($smallSize) {
    font-size: 14px;
    height: 2.7rem;
    padding: 0.15rem 0.6rem;
  } @else {
    font-size: 16px;
    height: 3rem;
    padding: 0.2rem 0.75rem;
  }

  svg {
    width: 22px;
    height: 22px;
    margin-right: 6px;
    fill: $cobalt-blue;
  }
}
