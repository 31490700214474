@import '~@astrid/components/src/scss/utils';

.container {
  background-color: white;
  border: 2px solid $light-grey;
  border-radius: 16px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: row;

  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.input {
  border: none;
  font-size: 1.25rem;
  padding: 1rem 1.5rem;
  border-radius: 16px;
  outline: none;
  display: block;
  flex: 1 0 0;
  width: 100%;
  &::placeholder {
    color: $grey;
  }

  @include breakpoint(xs) {
    text-align: center;
  }
}

.badge {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 1.1rem;
  font-weight: bold;
}

.divider {
  background-color: $very-light-grey;
  width: 3px;
  @include breakpoint(xs) {
    height: 3px;
    width: 100%;
  }
}
