@import '~@astrid/components/src/scss/utils';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.25rem 1rem 0;
  max-width: 100%;
  @include breakpoint(xs) {
    text-align: center;
  }
}

.box {
  background-color: white;
  padding: 2rem 2rem;
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.button {
  font-size: 1.25rem;
  padding: 1rem 3rem;
}

.error {
  color: red;
  margin-top: 1rem;
}

.input {
  margin: 1.5rem 0;
  width: 100%;
}

.partners_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;

  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.partner_image {
  height: 7rem;
  width: 7rem;
  margin: 0 1rem;
  object-fit: contain;

  @include breakpoint(xs) {
    margin: 1rem 0;
  }
}

.success {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
