@import '../../../scss/utils';

.reading-exercise {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100%;
  flex: 1 0 auto;
  flex-direction: column;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
  background-color: $blue-grey;
  @include breakpoint(xs) {
    padding: 4px 4px 16px;
  }

  &--detailed {
    @extend .reading-exercise;
    justify-content: flex-start;
    @include breakpoint(xs) {
      padding-top: 80px;
      padding-bottom: 64px;
    }
  }

  &__content {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;

    img {
      max-height: 330px;
      border-radius: 10px;
      margin-bottom: 32px;
      box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.25);

      @include breakpoint(xs) {
        width: calc(100% - 1rem);
        max-width: 330px;
        margin-bottom: 0;
      }
    }
  }

  &__listen-button {
    position: absolute;
    top: 28px;
    right: 80px;
    @include breakpoint(xs) {
      top: 16px;
      right: 10px;
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    &-progress {
      position: absolute;
      left: -90px;
    }

    &-button {
      position: absolute;
      right: -100px;
      padding: 0.75rem 1.25rem;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__continue-button {
    position: absolute;
    bottom: 28px;
    right: 80px;
    svg {
      width: 0px;
      height: 20px;
      transition: all 250ms;
    }

    &:hover {
      svg {
        width: 20px;
        margin-left: 5px;
      }
    }

    @include breakpoint(xs) {
      bottom: 16px;
      right: 10px;
    }
  }

  &__sentence-container {
    max-width: 1440px;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 24px 0 36px;

    &--success {
      @extend .reading-exercise__sentence-container;
      animation: success 700ms;
    }
  }

  &__word {
    padding: 0px 4px;
    margin: 0;

    &--wrong {
      @extend .reading-exercise__word;
      color: $cerise !important;
      animation: $shake-animation;
    }

    &--ok {
      @extend .reading-exercise__word;
      color: $burnt-yellow;
    }

    &--success {
      @extend .reading-exercise__word;
      color: $success-green;
    }
  }

  &__character {
    width: 350px;
    height: 350px;
  }

  &__replay {
    position: absolute;
    top: 24px;
    left: 16px;
    @include breakpoint(xs) {
      left: 8px;
    }
  }
}

@keyframes success {
  50% {
    opacity: 0.7;
    transform: scale(1.1);
  }
}
