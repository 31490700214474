@import '../../scss/utils';

.typography {
  &__h1 {
    font: 3.5rem/3.5rem $workSansFont;
  }

  &__h2 {
    font: 2.5rem/2.5rem $workSansFont;
  }

  &__h3 {
    font: 2rem/2rem $workSansFont;
  }

  &__h4 {
    font: 1.5rem/1.5rem $workSansFont;
  }

  &__exercise-s {
    font: 1.5rem/2rem $lexendFont;
  }

  &__exercise-m {
    font: 1.75rem/3rem $lexendFont;
  }

  &__exercise-l {
    font: 2.5rem/3rem $lexendFont;
  }

  &__exercise-xl {
    font: 3rem/3.5rem $lexendFont;
  }

  &__body {
    font: 1.125rem/1.8125rem $lexendFont;
  }

  &__button-link {
    font: 1rem/1.25rem $lexendFont;
  }

  &__label {
    font: 0.875rem/1rem $lexendFont;
  }

  &--color {
    &-primary {
      color: black;
    }

    &-secondary {
      color: $text-secondary;
    }
  }
}
