@import '../../scss/utils';

.rate {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__good {
    color: $success-green;
    svg {
      fill: $success-green;
      background-color: rgba($success-green, 0.2);
    }
  }

  &__bad {
    color: $cerise;
    svg {
      fill: $cerise;
      background-color: rgba($cerise, 0.2);
    }
  }

  p {
    font-size: 22px;
    margin: 0 0 16px;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 12px;
      transition: 230ms all;
      &:active {
        transform: scale(1.1);
      }

      svg {
        width: 70px;
        height: 70px;
        padding: 18px;
        border-radius: 100%;
        cursor: pointer;
        margin: 0 10px;
      }
    }
  }
}
