@import '~@astrid/components/src/scss/utils';

.header {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.button {
  width: 100%;
  font-size: 1.25rem;
}

.form {
  margin-bottom: 1rem;
  width: 100%;
}

.error {
  color: red;
  font-weight: bold;
  margin-bottom: 1rem;
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.25rem;

  .name {
    flex: 1;
  }
  .flag {
    font-size: 2rem;
  }
  .code {
    font-weight: bold;
  }
}

.selector {
  width: 6.5rem;

  .flip {
    flex: 0;
  }

  .option {
    gap: 0.2rem;
  }

  .flag {
    font-size: 1.4rem;
    position: relative;
    top: 0.1rem;
  }

  .name {
    display: none;
  }

  .code {
    font-weight: normal;
    font-size: 1.25rem;
    font-family: $lexendFont;
  }
}

.selectText {
  font-weight: normal;
  font-size: 1.25rem;
  font-family: $lexendFont;
  position: relative;
  top: 0.1rem;
}

.input {
  margin-bottom: 1rem;
  height: 68px;

  @include breakpoint(xs) {
    height: auto;
  }
}

.flip {
  display: inline-flex;
  align-items: center;
  flex: 1;
  gap: 1rem;
  flex-direction: row-reverse;
}
