@import '../../../../scss/utils';

.reading-score-display {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 22px;

  &__layout {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 10px;
    border-radius: 14px;
    padding: 16px 20px;
    background-color: white;
    animation: grow 600ms 1 normal;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  }

  &__description {
    margin-left: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    p {
      margin: 0 0 10px;
    }
    span {
      color: $black-76;
    }
  }
}
