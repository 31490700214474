.cancel-confirm-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button:first-of-type {
    margin-right: 16px;
  }
}
