@import '../../scss/utils';

// Add padding to slick-track class coming from react-slick lib
.slick-track {
  padding: 8px 0 11px 0;
}

.book-carousel {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0.5rem 0;

  &__padding {
    padding: 10px 0;
  }

  &__loading {
    height: 240px !important;
    width: auto !important;
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0.5rem;
    padding: 0.5rem;
    border-radius: 10px;
    outline: none;

    & > div {
      &:first-of-type {
        height: 27px;
        width: 50px;
        border-radius: 10px;
        background-color: white;
        animation: small-pulse 500ms linear infinite;
      }
      &:last-of-type {
        height: 24px;
        width: 50%;
        border-radius: 10px;
        background-color: white;
        animation: small-pulse 500ms linear infinite;
      }
    }
  }

  &:hover {
    button {
      display: block;
      @include breakpoint(xs) {
        display: none;
      }
    }
  }

  & > button {
    position: absolute;
    display: none;
    background: none;
    width: 35px;
    padding: 0;
    z-index: 10;
    top: 50%;
    transform: translate(0, -50%);

    svg {
      width: 45px;
      height: 45px;
      fill: $cobalt-blue;
    }

    &[class='right'] {
      right: -35px;
    }
    &[class='left'] {
      & > svg {
        transform: rotate(180deg);
      }
      left: -35px;
    }
  }

  &__fixer {
    min-height: 0;
    min-width: 0;
  }
}

@keyframes small-pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
}
