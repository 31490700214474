@import '../../scss/utils';

.demo-book {
  display: flex;
  flex: 1 0 auto;
  position: relative;
  width: 100%;
  min-height: 100%;

  img {
    box-shadow: none;
  }

  &__next-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: transparent;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-left: 6px;
      width: 20px;
      height: 20px;
    }

    &:hover {
      color: $cobalt-blue;
      svg {
        fill: $cobalt-blue;
      }
    }
  }
}
