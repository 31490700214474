@import '../../scss/utils';

.listen-sentence {
  border-radius: 14px;
  width: fit-content;
  margin-bottom: 2px;
  svg {
    margin-right: 6px;
  }
  &--playing {
    @extend .listen-sentence;
    padding-bottom: 1px;
    animation: play-pulse 1s infinite;
  }

  &--jump {
    @extend .listen-sentence;
    animation: jump 1s 2;
  }

  &--no-text {
    @extend .listen-sentence;
    svg {
      margin: 0;
    }
  }
}

@keyframes jump {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes play-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
