@import '../../../../scss/utils';

.recording-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 92px;

  &__progress {
    height: 12px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: lightgray;
    min-width: 450px;

    @include breakpoint(xs) {
      min-width: 270px;
    }

    &-indicator {
      height: 100%;
      border-radius: 10px;
      margin: 0;
      padding: 0;
      background-color: $maize;
      transition: 250ms all linear;
    }
  }

  &__button {
    border-radius: 100%;
    padding: 10px;
    background-color: $maize;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 500ms all;

    svg {
      width: 40px;
      height: 40px;
      transition: 500ms all;
    }

    &--playing {
      @extend .recording-player__button;
      background-color: lightgray;
      padding: 8px;

      svg {
        width: 30px;
        height: 30px;
        fill: grey;
      }
    }
  }
}
