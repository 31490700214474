.exercise-transition {
  width: 100%;
  display: flex;
  min-height: 100%;
  flex: 1 0 auto;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  &--animate {
    @extend .exercise-transition;
    transform-origin: right;
    animation: touchscreen-transition 500ms 1 ease;
  }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    will-change: opacity, transform;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: overlay-anim 660ms 1 ease;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
  }
}

@keyframes overlay-anim {
  from {
    background-color: rgba(0, 0, 0, 0.1);
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes touchscreen-transition {
  from {
    opacity: 0;
    transform: scaleX(0);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}
